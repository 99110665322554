import React from 'react';
import { Button } from '@movember/mo-gel';
import styled from 'styled-components';
import { StyledHeaderContainer, StyledSaveExitContainer } from '../../questionnaire.styled';

interface IHeaderContainerProps {
  setShowSavedModal: (val: boolean) => void;
}

const SaveExitButton = styled(Button)`
  &:hover {
    background: rgba(62, 126, 179, 0.15);
  }
`;

export const HeaderContainer: React.FC<IHeaderContainerProps> = ({ setShowSavedModal }) => (
  <StyledHeaderContainer>
    <StyledSaveExitContainer>
      <SaveExitButton
        secondary
        small
        width="auto"
        onClick={() => {
          setShowSavedModal(true);
        }}
      >
        Save and Exit
      </SaveExitButton>
    </StyledSaveExitContainer>
  </StyledHeaderContainer>
);
